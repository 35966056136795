import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPaginatedResponse } from 'src/app/model/api-paginated-response.model';
import { ApiResponse } from 'src/app/model/apiresponse.model';
import { DocumentVersion } from 'src/app/model/document-version.model';
import { Document } from 'src/app/model/document.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  get(organizationId: string, documentId: string): Observable<ApiResponse<Document | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/document/${documentId}`,
      {
        params: {
          'with[]': ['versions']
        }
      }
    )
  }

  list(organizationId: string): Observable<ApiResponse<Document[] | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/document`,
      {
        params: {
          orderBy: 'name',
          asc: 1,
          'with[]': ['versions', 'businessProcesses'],
          'withCount[]': ['versions', 'businessProcesses']
        }
      }
    );
  }

  paginatedList(organizationId: string, page: number, pageSize: number): Observable<ApiPaginatedResponse<Document[] | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/document`,
      {
        params: {
          page: page,
          limit: pageSize,
          orderBy: 'name',
          asc: 1,
          'with[]': ['versions', 'businessProcesses'],
          'withCount[]': ['versions', 'businessProcesses']
        }
      }
    );
  }

  paginatedListByType(organizationId: string, page: number, pageSize: number, type: string): Observable<ApiPaginatedResponse<Document[] | null>> {
    let params: { [Key: string]: any } = {
      page: page,
      limit: pageSize,
      orderBy: 'name',
      asc: 1,
      'with[]': ['versions', 'businessProcesses'],
      'withCount[]': ['versions', 'businessProcesses']
    };

    if (type != null) {
      params['type'] = type;
    }
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/document`,
      {
        params: params
      }
    );
  }

  filteredPaginatedListByType(organizationId: string, page: number, pageSize: number, type: string, filter: string, filterValue: string): Observable<ApiPaginatedResponse<Document[] | null>> {
    let params: { [Key: string]: any } = {
      page: page,
      limit: pageSize,
      orderBy: 'name',
      asc: 1,
      'with[]': ['versions', 'businessProcesses'],
      'withCount[]': ['versions', 'businessProcesses']
    };

    if (type != null) {
      params['type'] = type;
    }

    if (filterValue != null && filterValue !== '') {
      params['orderBy'] = filter;
      params['filterBy'] = filter;
      params['filter'] = filterValue;
    }
    
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/document`,
      {
        params: params
      }
    );
  }

  filteredPaginatedList(organizationId: string, page: number, pageSize: number, filter: string, filterValue: string): Observable<ApiPaginatedResponse<Document[] | null>> {
    let params: { [Key: string]: any } = {
      page: page,
      limit: pageSize,
      orderBy: 'name',
      asc: 1,
      'with[]': ['versions', 'businessProcesses'],
      'withCount[]': ['versions', 'businessProcesses']
    };

    if (filterValue != null && filterValue !== '') {
      params['orderBy'] = filter;
      params['filterBy'] = filter;
      params['filter'] = filterValue;
    }
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/document`,
      {
        params: params
      }
    );
  }

  create(organizationId: string, document: Document, file: Blob): Observable<ApiResponse<Document  | null>> {
    const formData = new FormData();
    formData.append('file', file);
    Object.entries(document).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return this.http.post<any>(
      `${environment.apiUrl}/${organizationId}/document`,
      formData
    );
  }

  update(organizationId: string, document: Document): Observable<ApiResponse<Document | null>> {
    return this.http.put<ApiResponse<Document | null>>(
      `${environment.apiUrl}/${organizationId}/document/${document.id}`,
      document
    );
  }

  delete(organizationId: string, documentId: string): Observable<ApiResponse<Document | null>> {
    return this.http.delete<any>(
      `${environment.apiUrl}/${organizationId}/document/${documentId}`,
    );
  }

  addVersion(organizationId: string, documentVersion: DocumentVersion, file: Blob): Observable<ApiResponse<DocumentVersion | null>> {
    const formData = new FormData();
    formData.append('file', file);
    Object.entries(documentVersion).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return this.http.post<ApiResponse<DocumentVersion | null>>(
      `${environment.apiUrl}/${organizationId}/document/${documentVersion.document_id}/version`,
      formData
    );
  }

  deleteVersion(organizationId: string, documentId: string, versionId: string): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(
      `${environment.apiUrl}/${organizationId}/document/${documentId}/version/${versionId}`,
    );
  }

  downloadFile(organizationId: string, documentId: string, versionId: string): Observable<Blob> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/octet-stream');

    return this.http.get(
      `${environment.apiUrl}/${organizationId}/document/${documentId}/version/${versionId}/download`,
      {
        headers: headers,
        responseType: 'blob'
      }
    )
  }

}
